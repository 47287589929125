import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PostalCode } from './postal-code.model';
import Swal from 'sweetalert2'
import { SweetAlertModel } from 'src/app/models/sweetalert.model';
import { Order } from './order.model';
@Injectable({
  providedIn: 'root'
})
export class FormService {
  formOrder: FormGroup //pedido
  formCustomer: FormGroup //cliente
  formProperty: FormGroup //imóvel
  formAttachment: FormGroup //Anexos
  formInvoicing: FormGroup //Faturamento
  formNotes: FormGroup //Faturamento
  formCE: FormGroup //CE
  api: string = environment.host
  curDate: Date =  new Date()
  datepipe: DatePipe = new DatePipe('de-DE')
  id: number
  ADMINISTRADOR: number = 2
  bugetList = []
  docsList = []
  emitListDocs = new EventEmitter()
  emitOrder = new EventEmitter()
  constructor(private http: HttpClient) {
    const profile = Number(localStorage.getItem('profile'))
    this.formOrder =  new FormGroup({
      id: new FormControl({value: null, disabled: true}),
      dateOrder: new FormControl(this.datepipe.transform(this.curDate, 'yyyy-MM-dd')),
      idStateOrder: new FormControl(1, {validators: [Validators.required]}),
      dateVisit: new FormControl({value: null, disabled: profile != this.ADMINISTRADOR}),
      hourVisit: new FormControl({value: null, disabled: profile != this.ADMINISTRADOR}),
      idAgency: new FormControl(1, {validators: [Validators.required]}),
      idPartner: new FormControl(1, {validators: [Validators.required]}),
      idExpert: new FormControl(1),
    })
   

    this.formCustomer = new FormGroup({
      customers: new FormControl() 
    })
    this.formProperty= new FormGroup({
      idPropertyType: new FormControl(null, {validators: [Validators.required]}),
      idTypology: new FormControl(null, {validators: [Validators.required]}),
      idDateEntry: new FormControl(1, {validators: [Validators.required]}),
      contactName: new FormControl(null,{validators: [Validators.required]}),
      contact: new FormControl(null,{validators: [Validators.required]}), 
      addressProperty: new FormControl(null,{validators: [Validators.required]}), //lote
      plot: new FormControl(null,{validators: [Validators.required]}), //lote
      floor: new FormControl(null,{validators: [Validators.required]}), //andar
      fraction: new FormControl(null,{validators: [Validators.required]}),
      postalCodeProperty1: new FormControl(null, {validators: [Validators.required]}), //cp
      postalCodeProperty2: new FormControl(null, {validators: [Validators.required]}), //cp
      postalCodeProperty: new FormControl(null, {validators: [Validators.required]}), //cp
      locality: new FormControl(null, {validators: [Validators.required]}), 
      idLocalityProperty: new FormControl(null, {validators: [Validators.required]}),
      distrito: new FormControl(null, {validators: [Validators.required]}),
      idDistrito: new FormControl(1, {validators: [Validators.required]}),
      concelho: new FormControl(null, {validators: [Validators.required]}),
      idConcelho: new FormControl(1, {validators: [Validators.required]}),
      freguesia: new FormControl(null, {validators: [Validators.required]}),
      idFreguesia: new FormControl(1, {validators: [Validators.required]}),
      idVisitContact: new FormControl(1, {validators: [Validators.required]}),
    })

    this.formAttachment =  new FormGroup({
      propertyBookFilename: new FormControl(null), //Caderneta predial
      propertyBook: new FormControl(null), //Caderneta predial
      contentCertificateFilename: new FormControl(null), //Certidão de teor
      contentCertificate: new FormControl(null), //Certidão de teor
      floorPlanFilename: new FormControl(null), //Planta baixa
      floorPlan: new FormControl(null), //Planta baixa
      housingLicenseFilename: new FormControl(null), //Licença de habitação
      housingLicense: new FormControl(null), //Licença de habitação
      technicalSpecificationsFilename: new FormControl(null), //ficha técnica
      technicalSpecifications: new FormControl(null), //ficha técnica
      energyBillFilename: new FormControl(null), //Fatura de eletricidade
      energyBill: new FormControl(null), //Fatura de eletricidade
      otherAttachmentOneFilename: new FormControl(null),  //Outros anexos 1
      otherAttachmentOne: new FormControl(null),  //Outros anexos 1
      otherAttachmentTwo: new FormControl(null),//Outros anexos 2
      otherAttachmentTwoFilename: new FormControl(null),//Outros anexos 2
    })
    this.formInvoicing =  new FormGroup({
      idPaymentMethod: new FormControl(1, {validators: [Validators.required]}), //Forma de pagamento
      commission: new FormControl(false),
      docs: new FormControl(null)
    })
    this.formNotes =  new FormGroup({
      observation: new FormControl(null),
    })

    this.formCE =  new FormGroup({
      deliveryDate: new FormControl(),
      certificateNumber: new FormControl(),
      energeticCertificate: new FormControl(),
      energeticCertificateFilename: new FormControl(),
      propertyForm: new FormControl(), //Ficha do Imóvel
      propertyFormFilename: new FormControl(), //Ficha do Imóvel
      summaryReport: new FormControl(), //Relatório Síntese
      summaryReportFilename: new FormControl(), //Relatório Síntese
      provisionalCertificate: new FormControl(), //Certificado provisório
      provisionalCertificateFilename: new FormControl(), //Certificado provisório
      proformaInvoice: new FormControl(), //Fatura proforma
      proformaInvoiceFilename: new FormControl(), //Fatura proforma
      adenePaymentDetails: new FormControl(), //Dados de pagamentos adene
      adenePaymentDetailsFilename: new FormControl(), //Dados de  pagamentos adene
      reference: new FormControl(), //
      amount: new FormControl(),//MONTANTE
      adeneFeePaid: new FormControl(true), //Taxa adene paga
      adenePaymentDate: new FormControl(), //Data de pagamento adene
      idBank: new FormControl(), 
      declarationOfLaterDelivery: new FormControl(), //Declaração de entrega posterior
      declarationOfLaterDeliveryFilename: new FormControl(), //Declaração de entrega posterior
      disclaimer: new FormControl(), //Declaração de inseção
      disclaimerFilename: new FormControl(), //Declaração de inseção
      preFinished: new FormControl(false), //Pré finalizado
    })
  }

  setId(id: number){
    this.id = id
  }
  getId(){
    return this.id
  }

  getFormOrder(){
      return this.formOrder
  }

  getFormCustomer(){
    return this.formCustomer
  }

  getFormProperty(){
    return this.formProperty
  }

  getFormAttachment(){
    return this.formAttachment
  }

  getFormInvoicing(){
    return this.formInvoicing
  }
  getFormNotes(){
    return this.formNotes
  }

  getFormCE(){
    return this.formCE
  }

  getPostalCode(cp: string): Observable<PostalCode>{
    return this.http.get<PostalCode>(`${this.api}/cp/${cp}`)
  }

  downloadFile(file: string){
    return this.http.get(`${this.api}/download/${file}`,{responseType:'blob'})
  }

  removerValidacao(campo: string){
    
    this.formAttachment.controls[campo].clearValidators()
    this.formAttachment.controls[campo].updateValueAndValidity()
  }

  waitingDialog(title= 'Buscando pelo código informado', html = 'Fecharemos esta tela quando terminar a busca'){
    Swal.fire({
      title,
      html,
      didOpen: () => {
        Swal.showLoading()
      },
      allowOutsideClick: false
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log('I was closed by the timer')
      }
    })
  }

  closeDialog(){
    Swal.close()
  }

  setMessage(data: SweetAlertModel){
    return Swal.fire({
      title: data.title,
      text: data.message,
      icon: data.icon,
      html: data.html,
      confirmButtonText: 'Ok'
    })
  }

  setValueInForms(order: Order){
    
    this.getFormAttachment().controls.propertyBook.setValue({name: order.propertyBook, file: order.propertyBookFilename, type: order.propertyBookMimetype})
    this.getFormAttachment().controls.contentCertificate.setValue({name: order.contentCertificate, file: order.contentCertificateFilename, type: order.contentCertificateMimetype})
    this.getFormAttachment().controls.floorPlan.setValue({name: order.floorPlan, file: order.floorPlanFilename, type: order.floorPlanMimetype})
    this.getFormAttachment().controls.housingLicense.setValue({name: order.housingLicense, file: order.housingLicenseFilename, type: order.housingLicenseMimetype})
    this.getFormAttachment().controls.technicalSpecifications.setValue({name: order.technicalSpecifications, file: order.technicalSpecificationsFilename, type: order.technicalSpecificationsMimetype})
    this.getFormAttachment().controls.energyBill.setValue({name: order.energyBill, file: order.energyBillFilename, type: order.energyBillMimetype})
    this.getFormAttachment().controls.otherAttachmentOne.setValue({name: order.otherAttachmentOne, file: order.otherAttachmentOneFilename, type: order.otherAttachmentOneMimetype})
    this.getFormAttachment().controls.otherAttachmentTwo.setValue({name: order.otherAttachmentTwo, file: order.otherAttachmentTwoFilename, type: order.otherAttachmentTwoMimetype})
    
    
    this.getFormNotes().patchValue(order)
    

  }

  
  


  limparCampos(){
    this.getFormOrder().reset()
    this.getFormCustomer().reset()
    this.getFormProperty().reset()
    this.getFormAttachment().reset()
    this.getFormInvoicing().reset()
    this.getFormNotes().reset()


    this.getFormOrder().controls.dateOrder.setValue(this.datepipe.transform(this.curDate, 'yyyy-MM-dd'))
    this.getFormOrder().controls.idStateOrder.setValue(1)
    this.getFormOrder().controls.idAgency.setValue(1)
    
    
    this.getFormProperty().controls.idPropertyType.setValue(1)
    this.getFormProperty().controls.idTypology.setValue(1)
    this.getFormProperty().controls.idDateEntry.setValue(1)
    this.getFormProperty().controls.idDistrito.setValue(1)
    this.getFormProperty().controls.idConcelho.setValue(1)
    this.getFormProperty().controls.idFreguesia.setValue(1)
    this.getFormProperty().controls.idVisitContact.setValue(1)

    this.getFormAttachment().controls.propertyBookFilename.setValidators([Validators.required])

    this.getFormInvoicing().controls.idPaymentMethod.setValue(1)
    //this.getFormInvoicing().controls.billAnotherAmount.setValue(null)
    //this.getFormInvoicing().controls.billAnotherAmount.disable()
  }

  setValueBudget(obj: any){
    this.bugetList = [
      {id: obj.id, description: `${obj.property_type} ${obj.name}`, value: obj.value, fixed: true},
      {id: obj.id, description: `Taxa Adene: ${obj.name}`, value: obj.adene, fixed: true},
    ]
  }

  getBudgetList(){
    return this.bugetList
  }

  setRenewBudget(array = []){
    console.log('setRenewBudget', array);
    
    this.bugetList = array
  }

  addItemBudget(obj: any){
    this.bugetList.push(obj)
  }

  removeItemBudget(idx: number){
    this.bugetList.splice(idx, 1)
  }

  
}
